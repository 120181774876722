/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.export-button {
        color:rgba(0, 0, 0, 0.87);
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 4%);
        border: none;
        border-radius:4px;
        padding: 10px 16px;
        margin-top: 5px;
        margin-left: 20px;
        text-align: center;
        font-size: 14px;
        font-weight: 400px;
        margin-right: -8px;
    }

    // CSS DO ARQUIVO LOGIN.JS //
.loginBackground{
    background-image: url('../login-background-dark.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    // border-radius: 100px 0px 0px 100px;
    width: 70%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1300px) {
      width: 60%;
    }
    @media only screen and (max-width: 1100px) {
      width: 55%;
    }
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  
  .loginForm {
    width: 30%;
  height: 100vh;
  margin-right: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: margin-right 0.3s; /* Add a smooth transition effect */

  @media only screen and (max-width: 1300px) {
    margin-right: 60px;
    width: 40%;
  }
  @media only screen and (max-width: 1100px) {
    margin-right: 45px;
    width: 45%;
  }
  @media only screen and (max-width: 900px) {
    margin-right: 0;
    width: 100%;
  }
  }
  
  // CSS DO ARQUIVO CARD-RENTAL-TODAY-REQUESTS.JS //
.reportHeader {
  background-image: url('./card-relatorio.jpg');
  width: '100%';
   height: 'auto';
   background-size: 'cover';
   background-repeat: no-repeat;
}
.reportDate{
  font-size: 16px;
  color: #053665;
  font-weight: 500;
}
.reportMeeting{
  font-size: 20px;
  color: #053665;
  font-weight: 600
}
.reportText{
  font-size: 20px;
  color: #053665;
  font-weight: 400;
}
.reportIcon{
width: 24px;
height: 24px;
color: '#B0B0B0'
}

.reportMiniCardText{
color: '#B0B0B0';
font-size: 16px;
font-weight: 400;
text-align: left;

}
